<template>
  <div>
    <RemoverCropItem></RemoverCropItem>
  </div>
</template>

<script>
import RemoverCropItem from "~/components/sections/removerCropItem.vue";

export default {
  name: "RemoverCrop",
  metaInfo: {
    title: "Remover & Crop ",
  },
  data() {
    return {};
  },
  components: {
    RemoverCropItem,
  },
};
</script>

<style lang="scss" scoped></style>
