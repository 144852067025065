<template>
  <div class="editor">
    <div style="max-width: 600px">
      <label
        @dragover.prevent
        @drop.prevent="handleDrop"
        title="Replace image"
        for="img_smart"
        class="ai-dnd"
      >
        <div class="ai-dnd__title">
          Drag and drop an image <br />
          or browse to upload.
        </div>
        <div class="ai-dnd__btn">Upload image</div>
        <div class="ai-dnd__info">File must be JPEG, JPG or PNG and up to 40MB</div>
        <input
          ref="img_smart"
          id="img_smart"
          @change="handleUpload"
          type="file"
          accept="image/png, image/jpeg, image/jpg, image/webp"
          class="editor__chosen-bar-button-input"
        />
      </label>
      <div class="ai-noimg">
        <div class="ai-noimg__title">
          No image?<br />
          Try one of these:
        </div>
        <div class="ai-noimg__examples">
          <div
            @click="loadFromUrl('/img/ai-tools/ai-noimg__example-big-1.webp')"
            class="ai-noimg__example"
          >
            <picture>
              <img src="/img/ai-tools/ai-noimg__example-1.jpg" />
            </picture>
          </div>
          <div
            @click="loadFromUrl('/img/ai-tools/ai-noimg__example-big-2.jpeg')"
            class="ai-noimg__example"
          >
            <picture>
              <img src="/img/ai-tools/ai-noimg__example-2.jpg" />
            </picture>
          </div>
          <div
            @click="loadFromUrl('/img/ai-tools/ai-noimg__example-big-3.jpeg')"
            class="ai-noimg__example"
          >
            <picture>
              <img src="/img/ai-tools/ai-noimg__example-3.jpg" />
            </picture>
          </div>
          <div
            @click="loadFromUrl('/img/ai-tools/ai-noimg__example-big-4.jpeg')"
            class="ai-noimg__example"
          >
            <picture>
              <img src="/img/ai-tools/ai-noimg__example-4.jpg" />
            </picture>
          </div>
        </div>
      </div>
    </div>

    <div @click.self="handleCloseImageModal" v-show="imageModal" class="e-modal">
      <div class="e-modal__container big">
        <div @click="handleCloseImageModal" class="e-modal__close">
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.168977 0.180242C0.394279 -0.0600807 0.759567 -0.0600807 0.984869 0.180242L7.5 7.12971L14.0151 0.180242C14.2404 -0.0600807 14.6057 -0.0600807 14.831 0.180242C15.0563 0.420565 15.0563 0.810205 14.831 1.05053L8.31589 8L14.831 14.9495C15.0563 15.1898 15.0563 15.5794 14.831 15.8198C14.6057 16.0601 14.2404 16.0601 14.0151 15.8198L7.5 8.87029L0.984869 15.8198C0.759567 16.0601 0.394279 16.0601 0.168977 15.8198C-0.0563257 15.5794 -0.0563256 15.1898 0.168977 14.9495L6.68411 8L0.168977 1.05053C-0.0563256 0.810205 -0.0563257 0.420565 0.168977 0.180242Z"
              fill="black"
            />
          </svg>
        </div>
        <div class="e-modal__resize-container">
          <div class="e-modal__image-container">
            <div class="editor__canvas-wrap">
              <div ref="canvasContainer" class="editor__canvas-container" tabindex="0">
                <div
                  :style="{
                    width: `${canvasWidth * zoom}px`,
                    height: `${canvasHeight * zoom}px`,
                    minWidth: `${canvasWidth * zoom}px`,
                    minHeight: `${canvasHeight * zoom}px`,
                  }"
                  class="editor__canvas"
                  id="canvas-container"
                >
                  <div
                    id="canvas"
                    :style="{
                      width: `${canvasWidth * zoom}px`,
                      height: `${canvasHeight * zoom}px`,
                      minWidth: `${canvasWidth * zoom}px`,
                      minHeight: `${canvasHeight * zoom}px`,
                    }"
                  >
                    <canvas ref="can_image" :width="canvasWidth" :height="canvasHeight"></canvas>
                  </div>
                </div>
              </div>

              <div v-if="canvasImage != null" class="editor__zoom-container">
                <div v-if="zoomScale != 1" class="editor__zoom-input">
                  {{ Math.round(zoomScale * 100) }}%
                </div>
                <div @click="zoomAspect" class="editor__zoom-element">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 1024 1024"
                  >
                    <path
                      fill="#000000"
                      d="m160 96.064 192 .192a32 32 0 0 1 0 64l-192-.192V352a32 32 0 0 1-64 0V96h64v.064zm0 831.872V928H96V672a32 32 0 1 1 64 0v191.936l192-.192a32 32 0 1 1 0 64l-192 .192zM864 96.064V96h64v256a32 32 0 1 1-64 0V160.064l-192 .192a32 32 0 1 1 0-64l192-.192zm0 831.872-192-.192a32 32 0 0 1 0-64l192 .192V672a32 32 0 1 1 64 0v256h-64v-.064z"
                    />
                  </svg>
                </div>
                <div @click="handleZoomScale(true)" class="editor__zoom-element">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
                    width="20px"
                    height="20px"
                    viewBox="0 0 32 32"
                    version="1.1"
                  >
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      sketch:type="MSPage"
                    >
                      <g
                        id="Icon-Set"
                        sketch:type="MSLayerGroup"
                        transform="translate(-308.000000, -1139.000000)"
                        fill="#000000"
                      >
                        <path
                          d="M321.46,1163.45 C315.17,1163.45 310.07,1158.44 310.07,1152.25 C310.07,1146.06 315.17,1141.04 321.46,1141.04 C327.75,1141.04 332.85,1146.06 332.85,1152.25 C332.85,1158.44 327.75,1163.45 321.46,1163.45 L321.46,1163.45 Z M339.688,1169.25 L331.429,1161.12 C333.592,1158.77 334.92,1155.67 334.92,1152.25 C334.92,1144.93 328.894,1139 321.46,1139 C314.026,1139 308,1144.93 308,1152.25 C308,1159.56 314.026,1165.49 321.46,1165.49 C324.672,1165.49 327.618,1164.38 329.932,1162.53 L338.225,1170.69 C338.629,1171.09 339.284,1171.09 339.688,1170.69 C340.093,1170.3 340.093,1169.65 339.688,1169.25 L339.688,1169.25 Z M326.519,1151.41 L322.522,1151.41 L322.522,1147.41 C322.522,1146.85 322.075,1146.41 321.523,1146.41 C320.972,1146.41 320.524,1146.85 320.524,1147.41 L320.524,1151.41 L316.529,1151.41 C315.978,1151.41 315.53,1151.59 315.53,1152.14 C315.53,1152.7 315.978,1153.41 316.529,1153.41 L320.524,1153.41 L320.524,1157.41 C320.524,1157.97 320.972,1158.41 321.523,1158.41 C322.075,1158.41 322.522,1157.97 322.522,1157.41 L322.522,1153.41 L326.519,1153.41 C327.07,1153.41 327.518,1152.96 327.518,1152.41 C327.518,1151.86 327.07,1151.41 326.519,1151.41 L326.519,1151.41 Z"
                          id="zoom-in"
                          sketch:type="MSShapeGroup"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <div @click="handleZoomScale(false)" class="editor__zoom-element">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
                    width="20px"
                    height="20px"
                    viewBox="0 0 32 32"
                    version="1.1"
                  >
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      sketch:type="MSPage"
                    >
                      <g
                        id="Icon-Set"
                        sketch:type="MSLayerGroup"
                        transform="translate(-360.000000, -1139.000000)"
                        fill="#000000"
                      >
                        <path
                          d="M373.46,1163.45 C367.17,1163.45 362.071,1158.44 362.071,1152.25 C362.071,1146.06 367.17,1141.04 373.46,1141.04 C379.75,1141.04 384.85,1146.06 384.85,1152.25 C384.85,1158.44 379.75,1163.45 373.46,1163.45 L373.46,1163.45 Z M391.688,1169.25 L383.429,1161.12 C385.592,1158.77 386.92,1155.67 386.92,1152.25 C386.92,1144.93 380.894,1139 373.46,1139 C366.026,1139 360,1144.93 360,1152.25 C360,1159.56 366.026,1165.49 373.46,1165.49 C376.672,1165.49 379.618,1164.38 381.932,1162.53 L390.225,1170.69 C390.629,1171.09 391.284,1171.09 391.688,1170.69 C392.093,1170.3 392.093,1169.65 391.688,1169.25 L391.688,1169.25 Z M378.689,1151.41 L368.643,1151.41 C368.102,1151.41 367.663,1151.84 367.663,1152.37 C367.663,1152.9 368.102,1153.33 368.643,1153.33 L378.689,1153.33 C379.23,1153.33 379.669,1152.9 379.669,1152.37 C379.669,1151.84 379.23,1151.41 378.689,1151.41 L378.689,1151.41 Z"
                          id="zoom-out"
                          sketch:type="MSShapeGroup"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div class="e-modal__image-side editor">
              <div class="e-modal__nav-item" v-show="!isColorPicker">
                <div class="e-modal__toggle">
                  <div
                    @click="handleSwitch(false)"
                    :class="['e-modal__toggle-item', !switcher && 'active']"
                  >
                    Before
                  </div>
                  <div
                    @click="handleSwitch(true)"
                    :class="['e-modal__toggle-item', switcher && 'active']"
                  >
                    After
                  </div>
                </div>

                <div class="e-modal__color-items">
                  <div
                    @click="setColor('rgba(0, 0, 0, 0)')"
                    class="e-modal__color-item image"
                    style="border-color: #000"
                  >
                    <img src="/img/alfa-icon.png" alt="" />
                  </div>
                  <div
                    @click="setColor('rgba(255, 255, 255, 1)')"
                    class="e-modal__color-item"
                    style="background: #fff; border-color: #000"
                  ></div>
                  <div
                    @click="setColor('rgba(0, 0, 0, 1)')"
                    class="e-modal__color-item"
                    style="background: #000; border-color: #000"
                  ></div>
                  <div
                    @click="setColor('rgba(65, 12, 217, 1)')"
                    class="e-modal__color-item"
                    style="background: #410cd9; border-color: #410cd9"
                  ></div>
                  <div
                    @click="setColor('rgba(255, 170, 170, 1)')"
                    class="e-modal__color-item"
                    style="background: #ffaaaa; border-color: #ffaaaa"
                  ></div>
                  <div
                    @click="setColor('rgba(255, 236, 140, 1)')"
                    class="e-modal__color-item"
                    style="background: #ffec8c; border-color: #ffec8c"
                  ></div>
                  <div @click="handleOpenColorPicker" class="e-modal__color-item image">
                    <img src="/img/picker.png" alt="" />
                  </div>
                </div>

                <div @click="handleDownload" class="e-modal__download-button">Download Image</div>
                <a ref="linkDownload" href="#" download=""></a>
                <div class="e-modal__image-size">{{ canvasWidth }} x {{ canvasHeight }} px</div>
              </div>

              <div class="editor__chosen-bar-container" v-show="isColorPicker">
                <div @click="handleCloseColorPicker" class="editor__back-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30px"
                    height="30px"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M20 12H4M4 12L10 6M4 12L10 18"
                      stroke="#000"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <template>
                  <div class="editor__filter-tabs border">
                    <div
                      @click="changeFillingTypeBackground('color')"
                      :class="['editor__filter-tab', fillingTabBackground == 'color' && 'active']"
                    >
                      Color
                    </div>
                    <div
                      @click="changeFillingTypeBackground('gradient')"
                      :class="[
                        'editor__filter-tab',
                        fillingTabBackground == 'gradient' && 'active',
                      ]"
                    >
                      Gradient
                    </div>
                  </div>
                  <!-- -- -->

                  <template v-if="fillingTabBackground == 'color'">
                    <div class="editor__chosen-bar-item">
                      <sketch-picker
                        @input="
                          (e) => {
                            changeColor(e, 'background', 'fillColor');
                          }
                        "
                        :value="background.fillColor"
                      ></sketch-picker>
                    </div>
                  </template>

                  <template v-if="fillingTabBackground == 'gradient'">
                    <div class="editor__filter-tabs-radio">
                      <div
                        @click="
                          () => {
                            this.background.gradientType = 'linear';
                          }
                        "
                        :class="[
                          'editor__filter-tab-radio',
                          background.gradientType == 'linear' && 'active',
                        ]"
                      >
                        <div class="editor__filter-tab-radio-icon"></div>
                        Linear
                      </div>
                      <div
                        @click="
                          () => {
                            this.background.gradientType = 'radial';
                          }
                        "
                        :class="[
                          'editor__filter-tab-radio',
                          background.gradientType == 'radial' && 'active',
                        ]"
                      >
                        <div class="editor__filter-tab-radio-icon"></div>
                        Radial
                      </div>
                    </div>

                    <div
                      class="editor__gradient-preset"
                      :style="{
                        background: generateGradient(
                          background.gradientColors,
                          background.gradientAngle,
                          background.gradientType,
                        ),
                      }"
                    ></div>

                    <div class="editor__gradient-colors">
                      <div
                        v-for="(color, index) in background.gradientColors"
                        :key="index"
                        class="editor__gradient-color"
                        :style="{ order: background.gradientColors[index].offset * 100 }"
                      >
                        <div class="editor__gradient-color-tools">
                          <div class="editor__gradient-color-name">Color {{ index + 1 }}</div>
                          <div class="editor__gradient-color-tools-right">
                            <div class="editor__gradient-tools-color">
                              <div
                                class="editor__gradient-tools-color-container"
                                @click="openColorPanel(`bg_${index}`)"
                                :style="{ background: background.gradientColors[index].color }"
                              ></div>
                              <div
                                :class="[
                                  'editor__gradient-tools-color-picker',
                                  colorPanel == `bg_${index}` && 'active',
                                ]"
                              >
                                <div
                                  @click="closeColorPanel"
                                  class="editor__gradient-tools-color-picker-close"
                                >
                                  <svg
                                    data-v-ba736d70=""
                                    width="15"
                                    height="15"
                                    viewBox="0 0 19 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      data-v-ba736d70=""
                                      d="M2 16.4L16.4 2"
                                      stroke="#000000"
                                      stroke-width="3.6"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                    <path
                                      data-v-ba736d70=""
                                      d="M2 2L16.4 16.4"
                                      stroke="#000000"
                                      stroke-width="3.6"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                  </svg>
                                </div>
                                <sketch-picker
                                  @input="
                                    (e) => {
                                      changeColor(
                                        e,
                                        'background',
                                        'gradientColors',
                                        index,
                                        'color',
                                      );
                                    }
                                  "
                                  :value="background.gradientColors[index].color"
                                ></sketch-picker>
                              </div>
                            </div>

                            <div
                              @click="deleteGradientColorItemBackground(index)"
                              class="editor__gradient-color-delete"
                              v-if="background.gradientColors.length > 1"
                            >
                              <svg
                                width="13"
                                height="17"
                                viewBox="0 0 13 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.5 7.96875C9.5 7.6755 9.276 7.4375 9 7.4375C8.724 7.4375 8.5 7.6755 8.5 7.96875V14.3438C8.5 14.6375 8.724 14.875 9 14.875C9.276 14.875 9.5 14.6375 9.5 14.3438V7.96875ZM7 7.96875C7 7.6755 6.776 7.4375 6.5 7.4375C6.224 7.4375 6 7.6755 6 7.96875V14.3438C6 14.6375 6.224 14.875 6.5 14.875C6.776 14.875 7 14.6375 7 14.3438V7.96875ZM4.5 7.96875C4.5 7.6755 4.276 7.4375 4 7.4375C3.724 7.4375 3.5 7.6755 3.5 7.96875V14.3438C3.5 14.6375 3.724 14.875 4 14.875C4.276 14.875 4.5 14.6375 4.5 14.3438V7.96875ZM12 14.875C12 16.0485 11.1045 17 10 17H3C1.8955 17 0.999999 16.0485 0.999999 14.875V6.375H12V14.875ZM5 2.125H8V1.59375C8 1.29997 7.776 1.0625 7.5 1.0625H5.5C5.224 1.0625 5 1.29997 5 1.59375V2.125ZM0.999999 2.125H4V1.0625C4 0.476 4.448 0 5 0H8C8.552 0 9 0.476 9 1.0625V2.125H12C12.552 2.125 13 2.601 13 3.1875V4.25C13 4.8365 12.5525 5.31197 12.0005 5.3125H0.998992C0.447493 5.31197 -9.53674e-07 4.8365 -9.53674e-07 4.25V3.1875C-9.53674e-07 2.601 0.447999 2.125 0.999999 2.125Z"
                                  fill="#565656"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div>
                          <vue-slider
                            :min="0"
                            :max="1"
                            :interval="0.01"
                            v-model="background.gradientColors[index].offset"
                          ></vue-slider>
                        </div>
                      </div>
                      <div
                        @click="addGradientColorItemBackgrouond"
                        class="editor__gradient-color-add"
                      >
                        <svg
                          width="20"
                          height="19"
                          viewBox="0 0 20 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5 1.9C3.34314 1.9 2 3.17599 2 4.75V14.25C2 15.824 3.34315 17.1 5 17.1H15C16.6568 17.1 18 15.824 18 14.25V4.75C18 3.17599 16.6568 1.9 15 1.9H5ZM0 4.75C0 2.12665 2.23858 0 5 0H15C17.7614 0 20 2.12664 20 4.75V14.25C20 16.8733 17.7614 19 15 19H5C2.23857 19 0 16.8733 0 14.25V4.75ZM10 5.7C10.5523 5.7 11 6.12533 11 6.65V8.55H13C13.5523 8.55 14 8.97533 14 9.5C14 10.0247 13.5523 10.45 13 10.45H11V12.35C11 12.8747 10.5523 13.3 10 13.3C9.44771 13.3 9 12.8747 9 12.35V10.45H7C6.44772 10.45 6 10.0247 6 9.5C6 8.97533 6.44772 8.55 7 8.55H9V6.65C9 6.12533 9.44771 5.7 10 5.7Z"
                            fill="#565656"
                          />
                        </svg>
                        Add Colour
                      </div>
                    </div>
                    <template v-if="this.background.gradientType == 'linear'">
                      <div class="editor__chosen-bar-item">
                        <div class="editor__filter-label">
                          Rotate
                          <div class="editor__filter-label-side">
                            {{ background.gradientAngle }}
                          </div>
                        </div>
                        <vue-slider
                          :min="0"
                          :max="90"
                          :interval="1"
                          v-model="background.gradientAngle"
                        ></vue-slider>
                      </div>
                    </template>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <!-- <div class="e-modal__canvas-bottom"></div> -->
        </div>

        <div v-if="loading_image" class="loading-image">
          <div class="loader">
            <div class="square"></div>
            <div class="square"></div>
            <div class="square last"></div>
            <div class="square clear"></div>
            <div class="square"></div>
            <div class="square last"></div>
            <div class="square clear"></div>
            <div class="square"></div>
            <div class="square last"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import referencesApi from "~/api/references";

import fabric from "~/plugins/fabric";
import { Sketch } from "vue-color";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  name: "CropAndRemover",

  components: {
    "sketch-picker": Sketch,
    VueSlider,
  },

  data() {
    return {
      file: null,
      isColorPicker: false,

      switcher: true,

      colorPanel: null,

      imageModal: false,
      imageUrl: null,

      canvasImage: null,
      canvasImageWidth: 0,
      canvasImageHeight: 0,
      canvasImageUrl: null,
      canvasImageRemoveBG: false,
      canvasImageCrop: false,
      canvasImageBlocked: false,
      isReplaceImage: false,

      loading_image: false,

      canvasWidth: "540",
      canvasHeight: "675",
      zoom: "",
      zoomIndex: 0.5,
      zoomScale: 1,

      fillingTabBackground: "color",

      freezeNG: false,

      background: {
        fillType: "color",
        fillColor: "#ffffff",
        gradientType: "linear",
        gradientAngle: 0,
        gradientColors: [],
      },
    };
  },

  watch: {
    background: {
      deep: true,
      immediate: true,
      handler() {
        if (this.canvasImage) {
          this.canvasImage.set({
            gradientAngle: this.background.gradientAngle,
            gradientType: this.background.gradientType,
            gradientColors: this.background.gradientColors,
          });
          if (this.background.fillType == "color") {
            this.canvasImage.set({
              fillColor: this.background.fillColor,
            });
            this.canvasImage.setBackgroundColor(
              this.background.fillColor,
              this.canvasImage.renderAll.bind(this.canvasImage),
            );
          }
          if (this.background.fillType == "gradient") {
            this.normalizeGradientBackground();
          }
        }
      },
    },
  },

  methods: {
    handleCloseImageModal() {
      this.canvasImage.clear();

      this.imageModal = false;
      this.canvasImageWidth = 0;
      this.canvasImageHeight = 0;
      this.canvasImageUrl = null;
      this.canvasImageRemoveBG = false;
      this.canvasImageCrop = false;
      this.isReplaceImage = false;

      this.loading_image = false;
      this.canvasImageBlocked = false;

      this.$refs.img_smart.value = "";
      this.switcher = true;

      this.background.fillType = "color";
      this.background.fillColor = "rgba(0, 0, 0, 0)";
      this.background.gradientType = "linear";
      this.background.gradientAngle = 0;
      this.background.gradientColors = [];
    },

    generateRandomString(length) {
      let result = "";
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },

    handleDownload() {
      const url = this.canvasImage.toDataURL({
        format: "png",
      });
      this.$refs.linkDownload.href = url;
      this.$refs.linkDownload.download = "elegantFlyer.png";
      this.$refs.linkDownload.click();
    },

    handleDrop(event) {
      this.file = event.dataTransfer.files[0];
      this.handleAddImageModal();
    },

    handleUpload(event) {
      this.file = event.target.files[0];
      this.handleAddImageModal();
    },

    async loadFromUrl(url) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], "downloaded-image", { type: blob.type });

        this.file = file;
        this.handleAddImageModal();
      } catch (error) {
        alert("Failed to load image from URL. Please check the URL.");
      }
    },

    handleAddImageModal(remove = true) {
      let file = this.file;

      if (
        file.type != "image/png" &&
        file.type != "image/jpeg" &&
        file.type != "image/jpg" &&
        file.type != "image/webp"
      ) {
        alert("Incorrect file type!");
        return;
      }

      if (!remove) {
        const objects = this.canvasImage.getObjects();

        for (let i = 0; i < objects.length; i++) {
          if (objects[i].typeElement === "image") {
            this.canvasImage.remove(objects[i]);
          }
        }
      }

      if (this.canvasImage == null) {
        const ref = this.$refs.can_image;
        this.canvasImage = new fabric.Canvas(ref, {
          preserveObjectStacking: true,
          isDrawingMode: false,
          allowTouchScrolling: true,
          enableRetinaScaling: false,
        });
      }

      this.imageModal = true;

      const reader = new FileReader();
      reader.onload = (f) => {
        var data = f.target.result;
        fabric.Image.fromURL(data, (img) => {
          this.canvasWidth = img.width;
          this.canvasHeight = img.height;
          this.canvasImage.setWidth(img.width);
          this.canvasImage.setHeight(img.height);

          this.canvasImage.renderAll();
          this.canvasImage.calcOffset();

          this.zoomAspect();

          const setImg = img.set({
            left: 0,
            top: 0,
            opacity: 1,
            id: this.generateRandomString(10),
            typeElement: "image",
            src: img._element.currentSrc,
            lockMovementX: true,
            lockMovementY: true,
            selectable: false,
          });
          this.canvasImage.add(setImg);

          if (remove) {
            this.handleBGRemove();
          }
        });
      };
      reader.readAsDataURL(file);
    },

    zoomAspect() {
      setTimeout(() => {
        let windowWidth = 755;
        let windowHeigth = 555;

        if (window.innerWidth <= 1200) {
          windowWidth = window.innerWidth - 460;
          windowHeigth = window.innerHeight - 110;
        }

        if (window.innerWidth <= 991) {
          windowWidth = window.innerWidth - 260;
          windowHeigth = window.innerHeight - 420;
        }

        if (window.innerWidth <= 568) {
          windowWidth = window.innerWidth - 80;
          windowHeigth = window.innerHeight - 220;
        }

        const zoomHeight = windowHeigth / this.canvasHeight;
        const zoomWidth = windowWidth / this.canvasWidth;
        let zoomTotal = 1;

        if (zoomHeight < 1 || zoomWidth < 1) {
          if (zoomHeight < zoomWidth) {
            zoomTotal = zoomHeight;
          } else {
            zoomTotal = zoomWidth;
          }
        }

        this.zoom = zoomTotal;
        this.zoomIndex = zoomTotal;
        this.zoomScale = 1;

        const lowerCanvas = this.canvasImage.lowerCanvasEl;

        const upperCanvas = this.canvasImage.upperCanvasEl;

        lowerCanvas.style.width = `${this.canvasWidth * this.zoom}px`;
        lowerCanvas.style.height = `${this.canvasHeight * this.zoom}px`;

        upperCanvas.style.width = `${this.canvasWidth * this.zoom}px`;
        upperCanvas.style.height = `${this.canvasHeight * this.zoom}px`;
      }, 0);
    },

    handleZoomScale(up) {
      if (up) {
        if (this.zoomScale < 2) {
          this.zoomScale += 0.1;
        }
      } else {
        if (this.zoomScale > 0.2) {
          this.zoomScale = this.zoomScale - 0.1;
        }
      }

      this.zoom = this.zoomIndex * this.zoomScale;

      const lowerCanvas = this.canvasImage.lowerCanvasEl;

      const upperCanvas = this.canvasImage.upperCanvasEl;

      lowerCanvas.style.width = `${this.canvasWidth * this.zoom}px`;
      lowerCanvas.style.height = `${this.canvasHeight * this.zoom}px`;

      upperCanvas.style.width = `${this.canvasWidth * this.zoom}px`;
      upperCanvas.style.height = `${this.canvasHeight * this.zoom}px`;
    },

    handleBGRemove() {
      this.loading_image = true;
      this.canvasImageBlocked = true;

      let file = this.file;

      const data = new FormData();
      data.append("image", file);

      const objects = this.canvasImage.getObjects();
      let image = null;
      let crop = null;

      for (let i = 0; i < objects.length; i++) {
        image = objects[i];
      }

      referencesApi
        .imageRemoveBackground(data)
        .then((res) => {
          if (res.data.jobId) {
            this.getImageUrl(res.data.jobId);
          }
        })
        .catch((error) => {
          this.loading_image = false;
          console.log(error);
        })
        .finally(() => {});
    },

    getImageUrl(jobId) {
      referencesApi
        .getImageRemoveBackground(`/${jobId}`)
        .then((res) => {
          if (res.data.url == null && this.imageModal) {
            setTimeout(() => {
              this.getImageUrl(jobId);
            }, 2000);
          } else {
            this.setSrcImageCanvas(res.data.url);
            this.imageUrl = res.data.url;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading_image = false;
        })
        .finally(() => {});
    },

    handleSwitch(item) {
      this.switcher = item;
      if (item) {
        this.setSrcImageCanvas(this.imageUrl);
      } else {
        this.handleAddImageModal(false);
      }
    },

    setSrcImageCanvas(url) {
      this.canvasImageUrl = url;

      this.loading_image = false;
      const objects = this.canvasImage.getObjects();

      for (let i = 0; i < objects.length; i++) {
        if (objects[i].typeElement === "image") {
          objects[i].setSrc(
            url,
            () => {
              this.canvasImage.renderAll();
            },
            {
              crossOrigin: "anonymous",
            },
          );
        }
      }
    },

    changeFillingTypeBackground(type) {
      if (type == "color" && this.canvasImage.fillType != "color") {
        this.canvasImage.set({
          fillType: "color",
          fill: this.canvasImage.fillColor,
        });
        this.background.fillType = "color";
        this.fillingTabBackground = "color";
      }

      if (type == "gradient" && this.canvasImage.fillType != "gradient") {
        this.addGradientBackground(
          this.canvasImage.gradientAngle || 90,
          this.canvasImage.gradientType || "linear",
          this.canvasImage.gradientColors || [],
        );
        this.background.fillType = "gradient";
        this.fillingTabBackground = "gradient";
      }
    },

    changeColor(color, first, second, third = null, four = null) {
      const { r, g, b, a } = color.rgba;
      if (four) {
        this[first][second][third][four] = `rgba(${r}, ${g}, ${b}, ${a})`;
      } else if (third) {
        this[first][second][third] = `rgba(${r}, ${g}, ${b}, ${a})`;
      } else {
        this[first][second] = `rgba(${r}, ${g}, ${b}, ${a})`;
      }
    },

    handleOpenColorPicker() {
      this.isColorPicker = true;
    },

    handleCloseColorPicker() {
      this.isColorPicker = false;
    },

    setColor(color) {
      this.changeFillingTypeBackground("color");
      this.background.fillColor = color;
    },

    openColorPanel(item) {
      this.colorPanel = item;
    },

    closeColorPanel() {
      this.colorPanel = null;
    },

    deleteGradientColorItemBackground(index) {
      this.background.gradientColors.splice(index, 1);
    },

    addGradientColorItemBackgrouond() {
      this.background.gradientColors.push({
        offset: 0.5,
        color: "#000",
      });
    },

    generateGradient(colors, angle, gradientType) {
      const sortedColors = [...colors].sort((a, b) => a.offset - b.offset);
      const gradientStops = sortedColors
        .map((colorStop) => {
          const percentage = colorStop.offset * 100;
          return `${colorStop.color} ${percentage}%`;
        })
        .join(", ");

      if (gradientType === "linear") {
        return `linear-gradient(${angle + 90}deg, ${gradientStops})`;
      } else if (gradientType === "radial") {
        return `radial-gradient(${gradientStops})`;
      } else {
        return "";
      }
    },

    normalizeGradientBackground() {
      if (!this.freezeNG) {
        (this.freezeNG = true),
          setTimeout(() => {
            this.freezeNG = false;
          }, 0);
        if (this.canvasImage.fillType == "gradient") {
          this.addGradientBackground(
            this.canvasImage.gradientAngle,
            this.canvasImage.gradientType,
            this.canvasImage.gradientColors,
          );
        }
      }
    },

    addGradientBackground(angle = 0, type = "linear", colors = []) {
      if (colors.length < 1) {
        colors = [
          { offset: 0, color: "#2980B9" },
          { offset: 1, color: "#F2C511" },
        ];

        this.background.gradientColors = colors;
      }

      if (type == "linear") {
        let radians = angle * (Math.PI / 180);

        let textWidth = this.canvasImage.getWidth();
        let textHeight = this.canvasImage.getHeight();

        let x2 = textWidth * Math.cos(radians);
        let y2 = textHeight * Math.sin(radians);

        if (angle === 90 || angle === 270) {
          x2 = 0;
          y2 = textHeight;
        } else if (angle === 180 || angle === 0 || angle === 360) {
          x2 = textWidth;
          y2 = 0;
        }

        const gradient = new fabric.Gradient({
          type: "linear",
          gradientUnits: "pixels",
          coords: { x1: 0, y1: 0, x2: x2, y2: y2 },
          colorStops: colors,
        });
        this.canvasImage.fillType = "gradient";
        this.canvasImage.gradientAngle = angle;
        this.canvasImage.gradientType = "linear";
        this.canvasImage.gradientColors = colors;

        this.canvasImage.setBackgroundColor(
          gradient,
          this.canvasImage.renderAll.bind(this.canvasImage),
        );
      } else {
        const gradient = new fabric.Gradient({
          type: "radial",
          gradientUnits: "pixels", // Использование единиц пикселей
          coords: {
            x1: this.canvasImage.getWidth() / 2, // Центр градиента по оси X
            y1: this.canvasImage.getHeight() / 2, // Центр градиента по оси Y
            r1: 0, // Начальный радиус
            x2: this.canvasImage.getWidth() / 2, // Конечный центр градиента по оси X
            y2: this.canvasImage.getHeight() / 2, // Конечный центр градиента по оси Y
            r2: Math.max(this.canvasImage.getWidth(), this.canvasImage.getHeight()) / 2, // Конечный радиус
          },
          colorStops: colors,
        });
        this.canvasImage.fillType = "gradient";
        this.canvasImage.gradientAngle = angle;
        this.canvasImage.gradientType = "radial";
        this.canvasImage.gradientColors = colors;

        this.canvasImage.setBackgroundColor(
          gradient,
          this.canvasImage.renderAll.bind(this.canvasImage),
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#canvas-container {
  overflow: hidden;
  position: relative;
}

#canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  height: fit-content;
  transform-origin: top left;
}

/deep/.vc-sketch {
  width: calc(100% - 10px);
  padding: 0;
  box-shadow: none;
  box-sizing: border-box;
}

/deep/.vc-sketch-hue-wrap {
  height: 20px;
}

/deep/.vc-sketch-alpha-wrap {
  height: 20px;
}

/deep/.vc-hue-picker {
  height: 18px;
  width: 7px;
}

/deep/.vc-alpha-picker {
  height: 18px;
  width: 7px;
}

/deep/.vc-sketch-color-wrap {
  width: 44px;
  height: 44px;
}

/deep/.vc-sketch-presets-color {
  width: 34px;
  height: 34px;
}

/deep/.vue-swatches {
  display: block;
}
/deep/.vue-swatches__trigger__wrapper {
  display: block;
  border: 1px solid #000;
  border-radius: 10px;
}
/deep/.vue-swatches__trigger {
  width: 100% !important;
}
/* Custom slider track */
/deep/.vue-slider.vue-slider-ltr {
  padding: 10px 0 !important;
  height: 28px !important;
  box-sizing: border-box !important;
  margin: 0 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background: linear-gradient(to right, #d85f54 20px, #565656 20px, #565656 100%);
    border-radius: 5px;
    top: 10px;
    left: -10px;
    right: -10px;
    height: 8px;
  }
}
/deep/.vue-slider-rail {
  background: transparent;
  border-radius: 5px;
}
/deep/.vue-slider-process {
  background: #d85f54;
}
/deep/.vue-slider-dot-tooltip-inner {
  display: none;
}
/deep/.vue-slider-dot-handle {
  background: #fff;
  box-shadow: none;
  border: 4px solid #565656;
  outline: 2px solid #fff;

  &-focus,
  &:hover {
    border: 4px solid #d85f54;
  }
}
/deep/.vue-slider-dot {
  width: 20px !important;
  height: 20px !important;
}
::-webkit-scrollbar {
  width: 6px; /* Ширина вертикального скроллбара */
  height: 6px; /* Высота горизонтального скроллбара */
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px; /* Скругление углов бегунка */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.editor {
  &__canvas-wrap {
    width: 800px;
    max-width: 800px;
    min-height: 300px;
    max-height: 600px;
    padding: 10px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    border: 1px solid #d9d9d9;

    @media (max-width: 1200px) {
      width: calc(100vw - 400px);
      max-width: calc(100vw - 400px);
      min-height: 300px;
      max-height: calc(100vh - 100px);
    }

    @media (max-width: 991px) {
      width: calc(100vw - 200px);
      max-width: calc(100vw - 200px);
      min-height: 300px;
      max-height: calc(100vh - 400px);
      border: none;
    }

    @media (max-width: 568px) {
      width: calc(100vw - 40px);
      max-width: calc(100vw - 40px);
      min-height: 200px;
      max-height: calc(100vh - 200px);
      border: none;
    }
  }

  &__canvas-container {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    padding: 0 10px 10px;
    outline: none;
  }

  &__canvas {
    height: fit-content;
    direction: ltr;
    background: url("/img/alfa.png") 50% / auto repeat;
  }

  &__zoom-container {
    position: absolute;
    bottom: 40px;
    right: 20px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid rgb(196, 196, 196);
    display: flex;
    align-items: center;
    padding: 5px 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    @media (max-width: 991px) {
      display: none;
    }
  }

  &__zoom-input {
    padding-right: 10px;
  }

  &__zoom-element {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      background: rgb(214, 214, 214);
    }
  }

  &__chosen-bar-container {
    display: flex;
    flex-direction: column;
    background: #fff;
    overflow-y: auto;
    max-height: 600px;
    width: 100%;
    padding-right: 10px;

    @media (max-width: 991px) {
      position: absolute;
      top: 0;
      left: 0;
      width: 330px;
      padding: 15px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      max-height: 610px;
    }
  }

  &__filter-tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 15px;

    &.border {
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 20px;
    }
  }
  &__filter-tab {
    margin: 0 0 10px 0;
    background: #f0eded;
    border-radius: 5px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #565656;
    font-size: 14px;
    font-weight: 400;
    width: 49%;

    .three & {
      width: 32%;
    }

    &:hover,
    &.active {
      background: #d75f54;
      color: #fff;
    }
  }
  &__filter-tabs-radio {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
  }

  &__filter-tab-radio {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #494949;
    padding: 0 15px;

    &-icon {
      width: 23px;
      height: 23px;
      border: 3px solid #565656;
      border-radius: 50px;
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.active {
      .editor__filter-tab-radio-icon {
        &::after {
          content: "";
          width: 11px;
          height: 11px;
          background: #d85f54;
          border-radius: 50px;
        }
      }
    }
  }

  &__gradient-preset {
    width: 100%;
    height: 70px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  &__gradient-colors {
    display: flex;
    flex-direction: column;
  }
  &__gradient-color-tools {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__gradient-color-name {
    color: #565656;
    font-size: 14px;
    font-weight: 700;
  }
  &__gradient-tools-color-container {
    width: 67px;
    height: 38px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #d9d9d9;
  }

  &__gradient-preset {
    width: 100%;
    height: 70px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  &__gradient-tools-color-picker {
    display: none;
    position: fixed;
    top: 250px;
    right: auto;
    left: 420px;
    width: 300px;
    background: #fff;
    border-radius: 5px;
    z-index: 10;
    padding: 50px 20px 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    &.active {
      display: block;
    }

    &-close {
      position: absolute;
      cursor: pointer;
      width: 35px;
      height: 35px;
      top: 10px;
      right: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;

      &:hover {
        background: #c0c0c0;
      }
    }
  }

  &__filter-label {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #565656;

    &-side {
      padding: 5px 10px;
      border-radius: 5px;
      border: 1px solid #f0eded;
      background: #fff;
      color: #565656;
      font-size: 14px;
    }
  }

  &__gradient-color-tools-right {
    display: flex;
    align-items: center;
  }

  &__gradient-tools-color {
    position: relative;
  }

  &__gradient-color-add {
    display: flex;
    align-items: center;
    order: 101;
    width: fit-content;
    font-size: 14px;
    color: #565656;
    padding: 10px;
    border: 1px solid #f0eded;
    border-radius: 10px;
    margin: 5px auto 0;
    cursor: pointer;

    svg {
      margin-right: 10px;
    }
  }
  &__gradient-color {
    position: relative;
    margin-bottom: 15px;

    &-delete {
      margin-left: 10px;
      display: flex;
      opacity: 0.7;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__back-icon {
    cursor: pointer;
  }
}
.e-modal {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;

  &__toggle {
    display: flex;
    border-radius: 10px;
    background: #e6e6e6;
    width: fit-content;

    @media (max-width: 991px) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__toggle-item {
    color: #a1a1a1;
    padding: 8px 10px;
    border: 2px solid transparent;
    cursor: pointer;

    &.active {
      border-radius: 10px;
      border: 3px solid #e65753;
      background: #f5f5f5;
    }

    @media (max-width: 991px) {
      font-size: 12px;
    }
  }

  &__download-button-container {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }

  &__canvas-bottom {
    display: flex;
    justify-content: flex-end;
    min-height: 38px;
  }

  &__canvas-button {
    margin-left: 10px;
    padding: 10px 35px;
    color: #fff;
    background: #d75f54;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background: #b3362b;
    }
  }

  &__canvas-container {
    margin: 20px 0;
    background: url("/img/alfa.png") 50% / auto repeat;
  }

  &__image-container {
    display: flex;
    position: relative;

    @media (max-width: 991px) {
      flex-direction: column;
    }
  }

  &__image-side {
    margin-left: 30px;
    width: 300px;

    @media (max-width: 991px) {
      margin: 20px auto 0;
    }
  }

  &__image-side-item {
    padding: 20px 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  &__image-icon {
    background: #f2f2f2;
    padding: 10px;
    border-radius: 10px;

    .active & {
      background: #d85f54;

      svg {
        path {
          fill: #fff;
        }

        rect {
          stroke: #fff;
        }
      }
    }

    .blocked & {
      pointer-events: none;
      background: #e7e7e7;

      svg {
        path {
          fill: #b8b8b8;
        }

        rect {
          stroke: #b8b8b8;
        }
      }
    }
  }

  &__image-text {
    padding-top: 5px;
    font-size: 10px;
    text-align: center;

    .blocked & {
      color: #b8b8b8;
    }
  }

  &__download-button {
    padding: 10px 35px;
    background: #d75f54;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
  }

  &__container {
    padding: 40px 35px;
    border-radius: 10px;
    background: #fff;
    position: relative;
    width: 340px;

    &.big {
      width: fit-content;
    }

    @media (max-width: 568px) {
      padding: 40px 10px;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 101;
  }

  &__font-items {
    display: flex;
    flex-wrap: wrap;
  }

  &__font-item {
    width: calc(25% - 10px);
    padding: 0 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
    cursor: pointer;
    text-align: center;
    border-radius: 10px;

    img {
      height: 100%;
    }
  }

  &__resize-title {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(201, 201, 201);
  }

  &__chosen-size-item {
    font-size: 13px;
    margin-bottom: 10px;
    padding: 10px;
    width: fit-content;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: #f0eded;
    }

    &.active {
      background: #f0eded;
      font-weight: 700;
    }
  }

  &__chosen-bar-item {
    padding: 10px 0 20px;
  }

  &__download-button {
    margin-top: 50px;
    padding: 20px;
    cursor: pointer;
    border-radius: 40px;
    background: #e65753;
    color: #fff;
    font-size: 16px;
    text-align: center;

    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }

  &__image-size {
    color: #494949;
    font-size: 12px;
    text-align: center;
    padding-top: 10px;
  }

  &__nav-item {
    @media (max-width: 991px) {
      display: block !important;
    }
  }

  //color

  &__color-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 50px;
    overflow: hidden;

    @media (max-width: 991px) {
      padding-top: 10px;
    }
  }

  &__color-item {
    width: 32px;
    height: 32px;
    border-radius: 50px;
    border: 2px solid transparent;
    margin: 0 5px;
    cursor: pointer;

    &.image {
      border: none;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.loading {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(#fff, 0.5);
  z-index: 100000;
}

.loading-image {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(#fff, 0.5);
  z-index: 100;
}

$squarew: 15px;
$squareh: 15px;
$squaremargin: 5px;
$loaderw: $squarew * 3 + $squaremargin * 2;
$loaderh: $squareh * 3 + $squaremargin * 2;
$delayenter: 0.3s;
$topenter: -10px;

@mixin transition($value) {
  -webkit-transition: $value;
  -moz-transition: $value;
  transition: $value;
}

@mixin delay($delay) {
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  animation-delay: $delay;
}
@-webkit-keyframes enter {
  0% {
    opacity: 0;
    top: $topenter;
  }
  5% {
    opacity: 1;
    top: 0px;
  }
  50.9% {
    opacity: 1;
    top: 0px;
  }
  55.9% {
    opacity: 0;
    top: -$topenter;
  }
}
@keyframes enter {
  0% {
    opacity: 0;
    top: $topenter;
  }
  5% {
    opacity: 1;
    top: 0px;
  }
  50.9% {
    opacity: 1;
    top: 0px;
  }
  55.9% {
    opacity: 0;
    top: -$topenter;
  }
}
@-moz-keyframes enter {
  0% {
    opacity: 0;
    top: $topenter;
  }
  5% {
    opacity: 1;
    top: 0px;
  }
  50.9% {
    opacity: 1;
    top: 0px;
  }
  55.9% {
    opacity: 0;
    top: -$topenter;
  }
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -$loaderw / 2;
  margin-top: -$loaderh / 2;
}
.square {
  background: #494949;
  width: $squarew;
  height: $squareh;
  float: left;
  top: $topenter;
  margin-right: $squaremargin;
  margin-top: $squaremargin;
  position: relative;
  opacity: 0;
  animation: enter 6s infinite;
}
.enter {
  top: 0px;
  opacity: 1;
}
.square:nth-child(1) {
  @include delay(6 * $delayenter);
}
.square:nth-child(2) {
  @include delay(7 * $delayenter);
}
.square:nth-child(3) {
  @include delay(8 * $delayenter);
  background: #d85f54;
}
.square:nth-child(4) {
  @include delay(3 * $delayenter);
}
.square:nth-child(5) {
  @include delay(4 * $delayenter);
}
.square:nth-child(6) {
  @include delay(5 * $delayenter);
}
.square:nth-child(7) {
}
.square:nth-child(8) {
  @include delay(1 * $delayenter);
}
.square:nth-child(9) {
  @include delay(2 * $delayenter);
}
.clear {
  clear: both;
}
.last {
  margin-right: 0;
}

.ai-dnd {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 4px 33.8px 0px #00000029;
  height: 353px;
  padding: 25px 22px 19px 24px;
  border-radius: 20px;
  margin-bottom: 47px;
  position: relative;
  @media (max-width: 1280px) {
    height: 500px;
  }
  @media (max-width: 767px) {
    height: 300px;
    padding: 15px;
  }
  input {
    visibility: hidden;
    position: absolute;
    height: 1px;
    width: 1px;
    top: 0;
    left: 0;
  }
  &:after {
    content: "";
    z-index: 1;
    position: absolute;
    left: 24px;
    right: 22px;
    top: 25px;
    bottom: 19px;
    border-radius: 10px;
    border: 2px dashed #ccc;
    text-align: center;
  }
  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 29.26px;
    text-transform: uppercase;
    margin-bottom: 30px;
    text-align: center;
    position: relative;
    z-index: 2;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 16px;
    }
  }
  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 352px;
    height: 63px;
    background-color: #e65753;
    border-radius: 40px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.38px;
    margin-bottom: 54px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: all 0.15s ease-in-out;
    @media (max-width: 767px) {
      width: 250px;
      height: 54px;
    }
    &:hover {
      filter: brightness(105%);
    }
  }
  &__info {
    font-size: 14px;
    font-weight: 400;
    line-height: 17.07px;
    text-align: center;
    position: relative;
    z-index: 2;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
}

.ai-noimg {
  display: flex;
  grid-gap: 28px;
  margin-bottom: 34px;
  @media (max-width: 1680px) {
    justify-content: center;
    flex-direction: column;
  }
  &__policy {
    font-size: 13px;
    font-weight: 400;
    line-height: 15.85px;
    text-align: center;
    & a {
      color: #000;
      text-decoration: underline;
      &:hover {
        color: #e65753;
      }
    }
  }
  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    text-transform: uppercase;
    @media (max-width: 1680px) {
      text-align: center;
      & br {
        display: none;
      }
    }
    @media (max-width: 1280px) {
      font-size: 24px;
      line-height: 24px;
    }
  }
  &__examples {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 14px;
    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__example {
    cursor: pointer;
    width: 147px;
    height: 84px;
    @media (min-width: 992px) and (max-width: 1280px) {
      width: 100%;
      height: 150px;
    }
    @media (max-width: 991px) {
      width: 100%;
      height: 120px;
    }
    & picture {
      & img {
        width: 147px;
        height: 84px;
        object-fit: cover;
        border-radius: 10px;
        transition: all 0.15s ease-in-out;
        border: 2px solid transparent;
        @media (min-width: 992px) and (max-width: 1280px) {
          width: 100%;
          height: 150px;
        }
        @media (max-width: 991px) {
          width: 100%;
          height: 120px;
        }
      }
    }
    &:hover {
      & picture img {
        filter: brightness(105%);
        border-color: #e65753;
      }
    }
  }
}
</style>
